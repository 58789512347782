import { AuthModel, UserModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'accessToken'
const AUTH_CURRENT_USER_KEY='currentUser_admin';
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  // const getPrevToken = JSON.parse(lsValue).admin_token;

  // console.log({ getPrevToken });

  // parseJwt(getPrevToken);

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getCurrentUser = (): UserModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_CURRENT_USER_KEY);
  if (!lsValue) {
    return;
  }


  try {
    const auth: UserModel = JSON.parse(lsValue) as UserModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    // console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
};

function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  //console.log(JSON.parse(jsonPayload));
  ;
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    // console.log(AUTH_LOCAL_STORAGE_KEY , localStorage.getItem(AUTH_LOCAL_STORAGE_KEY))
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setCurrent = (auth: UserModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);

    localStorage.setItem(AUTH_CURRENT_USER_KEY, lsValue);
  
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.removeItem(AUTH_CURRENT_USER_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY,AUTH_CURRENT_USER_KEY,getCurrentUser,setCurrent }
