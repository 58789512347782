import { Button } from "src/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import React, { useEffect, useMemo, useState } from "react";
import { Input } from "src/components/ui/input";
import { cn } from "src/helpers/index";
import debounce from "lodash.debounce";
import { X } from "lucide-react";
import { Label } from "src/components/ui/label";
import { options } from "src/Modules/UserOnboarding/User/Component/Modal/AddUser/moduleAdd";

interface SearchableDropDownProps {
  option: any[];
  handleChange: (val: any) => void;
  label?: string;
  value: any;
  placeholder: string;
  loading?:boolean
}
const SearchableDropDown = ({
  option,
  handleChange,
  label,
  value,
  placeholder,
  loading
}: SearchableDropDownProps) => {
  const [val, setValue] = useState<string>(value);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [labelValue, setLabelValue] = useState("");
  const onclick = (cur: any) => {
    handleChange(cur?.value);
    setValue(cur?.value);
    setPopoverOpen(false);
    setLabelValue(cur?.label);
  };

  const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };


  const debounceOnchange = debounce(onchange, 500);

  const data = useMemo(
    () =>
      searchTerm !== ""
        ? option.filter((cur: any) =>
            cur.label.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : option,
    [option, searchTerm]
  );

  useEffect(() => {
    if (value&&value!=="") {
      setValue(value);
      const label =
        value && value !== undefined
          ? option?.filter((cur: any) => cur?.value == value)[0]?.label
          : "";
      setLabelValue(label);
    }else return
  }, [value,option]);

  return (
    <div className="w-full">
      {label ? (
        <Label className="text-darkGray font-medium text-xs ml-2 uppercase">
          {label}
        </Label>
      ) : (
        <></>
      )}

      <Popover open={isPopoverOpen && !loading} onOpenChange={setPopoverOpen} >
        <PopoverTrigger asChild>
          {/* <Button variant="outline" className='w-full'>{val!==''?val:'Search'}</Button> */}
          <div className="w-full border border-[#64748B] p-2 h-[36px] rounded-md cursor-pointer text-xs font-normal">
            {val !== "" ? (
              <div className="w-full flex justify-between items-center text-xs font-normal">
                <div className="w-[95%] overflow-x-scroll whitespace-nowrap no-scrollbar">
                  {labelValue}
                </div>{" "}
                <X
                  size={16}
                  onClick={() => {
                    setValue("");
                    setSearchTerm("");
                    handleChange("");
                    setLabelValue("");
                  }}
                />
              </div>
            ) : (
              placeholder
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[300px]">
          <Input
            className="w-full border border-input border-[#64748B]"
            placeholder="Search"
            type="text"
            onChange={debounceOnchange}
            // value={val}
          />
          <div className="w-full mt-2 h-[300px] overflow-y-scroll">
            {data ? (
              data.map((cur: any) => (
                <div
                  onClick={() => onclick(cur)}
                  className={cn(
                    `w-full text-xs font-normal text-black cursor-pointer`,
                    { "font-medium": val === cur?.value }
                  )}
                >
                  {" "}
                  {cur?.label}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SearchableDropDown;
