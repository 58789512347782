import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import Navigations from './Navigation';
import { AuthProvider } from './Modules/Auth/Core';
import { PersistGate } from 'redux-persist/integration/react';

document.addEventListener('contextmenu', (event) => {
  event.preventDefault();
});

// Disable Developer Tools Shortcut Keys
document.addEventListener('keydown', (event) => {
  if (
    event.key === 'F12' || 
    (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J')) ||
    (event.ctrlKey && event.key === 'U')
  ) {
    event.preventDefault();
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AuthProvider>
      
      <PersistGate loading={null} persistor={persistor}>
        <Navigations />
        </PersistGate>
    
    </AuthProvider>
    </Provider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
