import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useReducer,
  ReactNode,
} from "react";


// import { LayoutSplashScreen } from '../../../Helpers'
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import Cookies from 'universal-cookie';



import { useLocation, useNavigate } from "react-router-dom";

import { ENUM_STATUS_CODE } from "src/helpers";
import { getUserByToken, logoutApi } from "src/services/services";
import { useDispatch } from "react-redux";
import { updateError, updateErrorCode, updateSuccessMess } from "src/redux/Reducers/errorHadlingSlicer";
import axios, { AxiosError } from "axios";
// import { getUserByToken } from "../Login/Context/core/_requests";


// import Splashscreen from "../Splashscreen";
interface AuthProviderProps {
  children: ReactNode;
}
type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  saveCurrent: (auth: UserModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  logoutHandling:()=>void
  logoutLoading:boolean
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  saveCurrent: () => {},
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  logoutHandling:()=>{},
  logoutLoading:false
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(authHelper.getCurrentUser());
  const [logoutLoading,setLogoutLoading]=useState(false)
  // console.log({ auth });


  const saveCurrent = (auth: UserModel | undefined) => {
    setCurrentUser(auth);
    if (auth) {
      authHelper.setCurrent(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    saveCurrent(undefined)
    // setCurrentUser(undefined);
  };
  const dispatch=useDispatch()

  const logoutHandling = async () => {
    setLogoutLoading(true);

    try {
      const postData = {
        refreshToken: auth?.refreshToken,
      };
      let apiResp = await logoutApi(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        logout();
        dispatch(updateSuccessMess("You’ve successfully logout out."));
      } else {
        dispatch(updateErrorCode(apiResp.code));
        dispatch(updateError(apiResp.message));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateErrorCode(error.response?.data.code));
        dispatch(updateError(error.response?.data.message));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(updateError(error.message));
      }
    } finally {
      setLogoutLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout,logoutHandling,logoutLoading,saveCurrent }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<AuthProviderProps> = ({ children }) => {

  const { auth, logout, setCurrentUser, currentUser, saveAuth,logoutHandling,logoutLoading,saveCurrent } = useAuth();

  // console.log("Auth Check", currentUser, auth);
  const navigate = useNavigate();
  const didRequest = useRef(false)
  const cookies = new Cookies();
  const { pathname, search } = useLocation();

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by authToken (IN OUR EXAMPLE IT'S admin_token) before rendering the application
  useEffect(() => {

    if (auth && !currentUser && auth.admin_token) {
      // console.log("cheked")
      requestUser(auth.admin_token);
    } else {
      setShowSplashScreen(false);
    }

    return () => { };
    // eslint-disable-next-line
  }, [currentUser, auth?.admin_token]);


  const requestUser = async (apiToken: string) => {
    try {


      const data: any = await getUserByToken()

      // console.log({ data })

      if (data && data?.code == ENUM_STATUS_CODE?.SUCCESS) {
// console.log(data?.data)
      

        saveCurrent({
          ...currentUser,
          isAuthenticated: data?.data?.isAuthenticated,
          user_id: data?.data?.user,
          role:data?.data?.user
        })
        saveAuth({
          ...auth,
          isLoadDetailsAvl: data?.data?.isLoadDetailsAvl,
          isMeterDetailsAvl: data?.data?.isMeterDetailsAvl,
          isPlantDetailsAvl: data?.data?.isPlantDetailsAvl ? data?.data?.isPlantDetailsAvl : false,
          isVerified:data?.data?.isVerified,
          userCode:data?.data?.usercode,
          role:data?.data?.role,
          disom:data?.data?.disom,
          countryCode:data?.data?.countryCode,
          state:data?.data?.state
      })

    
        if (cookies.get('kt-last-url')) {
          let lastpath = pathname + search || cookies.get('kt-last-url') || "";
          navigate({ pathname: lastpath });
        }
      }
      else {
        localStorage.setItem('kt-last-url', pathname + search)
        cookies.set('kt-last-url', pathname + search, { path: '/' });
        // logout();
        logoutHandling()
      }

    } catch (error) {
      // console.error(error);
      if (!auth?.admin_token) {
        // logout();
        logoutHandling()
      }
    } finally {
      setShowSplashScreen(false);
    }
    // return () => (<></>);
  };


  return showSplashScreen ? <></> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
