// src/utils/crypto.ts
// @ts-ignore
import * as CryptoJS from 'crypto-js';

// Use environment variables for your secret key in production
const SECRET_KEY:any = process.env.REACT_APP_BASE_URL_LOGIN_SECREATE_KEY ;

/**
 * Encrypt a password or any sensitive data
 * @param data The plain text data
 * @returns The encrypted string
 */
export const encryptData = (data: string | undefined): string => {
    if (!data) return ""; // Return an empty string or some default value
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
  };

/**
 * Decrypt an encrypted string
 * @param encryptedData The encrypted string
 * @returns The plain text string
 */
export const decryptData = (encryptedData: string): string => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};
