import React, { useEffect, useMemo } from "react";
import { ENUM_CONSUMER_TYPE, ENUM_PURCHASE_TYPE } from "src/helpers";
import {
  InvoiceListView,
  QueryRequestProvider,
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "../Context";

import DatatableMain from "src/components/commons/Table";
import { columnsInvoiceListViewBuy } from "../Context/TableComponents/table/columns/columnsInvoiceListViewBuy";
import { columnsInvoiceListViewSell } from "../Context/TableComponents/table/columns/columnsInvoiceListViewSell";
import { FilterObjProps } from "./Filters";
interface ApiResponse {
  data: {
    data: any[]; // Adjust 'any' to the actual shape of the data if known
  };
}
type Props = {
  filterObj: FilterObjProps;
};
const Main = ({ filterObj }: Props) => {
  const { state, updateState, updateFilter } = InvoiceListView();
  const { response }:any = useQueryResponse();
  const isLoading = useQueryResponseLoading();

  const data: any = useMemo(() => {
    if (isLoading) return [];

    return response?.data && Array.isArray(response.data?.data) && response.data?.data?.length>0
      ? response.data?.data.map((cur: any, index: number) => ({
          ...cur,
          sr_no: index + 1,
        }))
      : [];
  }, [response, isLoading]);



  const pagination: any = useQueryResponsePagination();

  useEffect(() => {
    updateFilter &&
      updateFilter({
        ...filterObj,
        items_per_page: 10,
        page: 1,
      });
    return () => {};
  }, [filterObj]);

  

  const column = useMemo(
    () =>
      filterObj?.side === ENUM_PURCHASE_TYPE?.BUY
        ? columnsInvoiceListViewBuy
        : columnsInvoiceListViewSell,
    [filterObj]
  );

  const pageIndexChange = (val: any, b: any) => {
    updateFilter &&
      updateFilter({
        items_per_page: b,
        page: val + 1,
      });
  };
  return (
    <div className="w-full h-full font-Roboto">
      <DatatableMain
        columns={column}
        data={data || []}
        paggination={pagination}
        hidePaggination={false}
        isLoading={isLoading}
        pageIndexChange={pageIndexChange}
      />
    </div>
  );
};

const ListViewTable: React.FC<Props> = ({ filterObj }) => {
  return (
    <div className="w-full h-[85%]">
      <QueryRequestProvider>
        <QueryResponseProvider>
          <Main filterObj={filterObj} />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </div>
  );
};
export default ListViewTable;
