import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface DashboardState {
  blockNumber: number | null;
  transactionId: string | undefined;
  error: boolean;
  isLoading: boolean;
  message: string | undefined;
  dataChanged: boolean;
  transactionData: any;
}

const initialState: DashboardState = {
  blockNumber: null,
  dataChanged: false,
  error: false,
  isLoading: false,
  message: "",
  transactionId: undefined,
  transactionData: {},
};

export const dashboardSlicer = createSlice({
  name: "dashboardSlicer",
  initialState,
  reducers: {
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    updateBlockNumber: (state, action: PayloadAction<any>) => {
      state.blockNumber = action.payload;
      // state.page = action.payload.pageIndex;
    },
    updateTransactionId: (state, action: PayloadAction<any>) => {
      state.transactionId = action.payload;
      // state.page = action.payload.pageIndex;
    },

    updateTransactionData: (state, action: PayloadAction<any>) => {
      state.transactionData = action.payload;
      // state.page = action.payload.pageIndex;
    },
  },
});

export const {
  setError,
  resetChangedState,
  resetError,
  updateBlockNumber,
  updateTransactionId,
  updateTransactionData,
} = dashboardSlicer.actions;
export const dashboardReducerState = (state: RootState) => (state).dashboard;
export default dashboardSlicer.reducer;
