import { combineReducers, configureStore } from "@reduxjs/toolkit";



import errorHadlingSlicer from "./Reducers/errorHadlingSlicer";
import dashboardSlicer from "./Reducers/dashboardSlicer";
import loginSlicer from "./Reducers/loginSlicer";
import metabaseReportSlicer from "./Reducers/metabaseReportSlicer"
import timeblockSlicer from "./Reducers/timeblockSlicer"
import {
  persistStore,
  persistReducer,
  
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import  dropdownSlicer  from "./Reducers/dropdownSlicer";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["metabase","login" ], 
};

const rootReducer = combineReducers({
  login: loginSlicer,
  errorBoundry: errorHadlingSlicer,
  dashboard:dashboardSlicer,
  metabase:metabaseReportSlicer,
  timeblock:timeblockSlicer,
  dropdown:dropdownSlicer
})
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       // Ignore redux-persist actions
  //       ignoredActions: [PERSIST, REHYDRATE, PAUSE, PURGE, REGISTER],
  //     },
  //   }),
});
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
