import React, { useEffect, useState } from "react";
import PurchasetypeFilter from "../../Filter/Component/PurchasetypeFilter";
import DateRanges from "../../Filter/Component/DateRanges";
import { Button } from "../../../../../components//ui/button";
import {
  dateForFirstOfMonthAndYesterdayDate,
  ENUM_PURCHASE_TYPE,
} from "../../../../../helpers";
import dayjs from "dayjs";
import DateRangeForInvoice from "../../../../../components/commons/DatePicker/DateRangeForInvoice";
import { DateRange } from "react-day-picker";
import UserdropdownMain from "src/components/commons/Dropdown/Userdropdown/UserdropdownMain";
import { InvoiceListView } from "../Context";

interface FilterMainProps {
  setFilterObje: (val: FilterObjProps) => void;
}

export interface FilterObjProps {
  startDate: string;
  endDate: string;
  side: string;
  userID: string;
}

const Filters = ({ setFilterObje }: FilterMainProps) => {
  const [userId, setUserId] = useState<string>("");
  const [purchaseType, setPurchaseType] = useState<string>(
    // ENUM_PURCHASE_TYPE?.BUY
    ""
  );

  const { state, updateState, updateFilter } = InvoiceListView();
  let dt = dateForFirstOfMonthAndYesterdayDate();

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: dt?.firstDay ? new Date(dt.firstDay) : new Date(),
    to: dt?.yesterday ? new Date(dt.yesterday) : new Date(),
  });

  const filterSubmit = () => {
    if (date?.to && date?.from) {
      setFilterObje({
        endDate: dayjs(new Date(date?.to)).format("YYYY-MM-DD"),
        side: purchaseType,
        startDate: dayjs(new Date(date?.from)).format("YYYY-MM-DD"),
        userID: userId,
      });
    }
  };

useEffect(()=>{
  if (date?.to && date?.from) {
    setFilterObje({
      endDate: dayjs(new Date(date?.to)).format("YYYY-MM-DD"),
      side: purchaseType,
      startDate: dayjs(new Date(date?.from)).format("YYYY-MM-DD"),
      userID: userId,
    });
  }else return
},[])

  const dropdownchange = (val: string) => {
    setUserId(val);
  };

  return (
    <div className="w-full ">
      <h1 className=" text-lg text-textLightBlack font-bold px-4 mt-2 whitespace-nowrap">
            Invoice List
          </h1>
      <div className="w-full flex space-x-2 items-center justify-end">
        
        <div className="w-64 ">
          <UserdropdownMain
            onchange={dropdownchange}
            userId={userId && userId !== undefined ? userId : ""}
            consumerType={purchaseType===ENUM_PURCHASE_TYPE?.BUY?'consumer':purchaseType===ENUM_PURCHASE_TYPE?.SELL?'prosumer':""}
          />
        </div>
        <div className="w-64 ">
          <PurchasetypeFilter setPurchaseType={setPurchaseType} />
        </div>

        <DateRangeForInvoice date={date} setDate={setDate} />
        <Button onClick={filterSubmit}>Submit</Button>
      </div>
    </div>
  );
};

export default Filters;
