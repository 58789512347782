import axios, { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ENUM_STATUS_CODE, NUMBER_PATTERN } from "../../../../helpers";
import {
    updateError,
    updateErrorCode,
    updateSuccessMess,
} from "../../../../redux/Reducers/errorHadlingSlicer";
import {
    loginReducerState,
    updateLoginData,
} from "../../../../redux/Reducers/loginSlicer";
import { RootState } from "../../../../redux/store";

import { useAuth } from "../../Core";
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSlot,
} from "../../../../components/ui/input-otp";

import FormSubmitBtn from "src/components/commons/Buttons/FormSubmitBtn";
import { loginOtpValidateApi, resendOtp } from "src/services/services";
import { Button } from "../../../../components/ui/button";
import Buttons from "src/components/commons/Buttons/Buttons";

const LOtp = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const [newotp, setNewOtp] = useState<string>();

    const { saveAuth, saveCurrent } = useAuth();
    const [timeRemaining, setTimeRemaining] = useState(180);

    const dispatch = useDispatch();
    const { data } = useSelector((state: RootState) => loginReducerState(state));

    const otpVerification = async () => {
        setLoading(true);

        try {
            let postData = {
                user: data?.user && data?.user,
                otp: newotp,
            };
            let apiResp = await loginOtpValidateApi(postData);
            dispatch(updateErrorCode(apiResp.code));
            if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
                saveCurrent({
                    userResponse: apiResp?.data?.user,
                    isAuthenticated: true,
                    user_id: apiResp?.data?.user?.id,
                    role: apiResp?.data?.user?.role,
                });

                saveAuth({
                    admin_token: apiResp.data?.tokens?.access?.token,
                    accessTokenExp: apiResp.data?.tokens?.access?.expires,
                    refreshTokenExp: apiResp.data?.tokens?.refresh?.expires,
                    refreshToken: apiResp.data?.tokens?.refresh?.token,
                    user_id: apiResp?.data?.user?.id,
                    user_name: apiResp?.data?.user?.username,
                    isVerified: apiResp?.data?.user?.isVerified,
                    role: apiResp?.data?.user?.role,
                    disom: apiResp?.data && apiResp?.data?.user?.disom,
                    countryCode: apiResp?.data && apiResp?.data?.countryCode,
                    state: apiResp?.data && apiResp?.data?.state,
                    userCode: apiResp?.data && apiResp?.data?.user?.usercode,
                });
            } else {
                dispatch(updateErrorCode(apiResp.code));
                dispatch(updateError(apiResp.message));
            }
            return apiResp.data;
        } catch (err) {
            let error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                dispatch(updateErrorCode(error.response?.data.code));
                dispatch(updateError(error.response?.data.message));
            } else {
                dispatch(
                    updateError("An unexpected error occurred. Please try again later.")
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const resendAgainLogin = async () => {
        setNewOtp("");

        try {
            let postdata = {
                user: data?.user && data?.user,
            };

            let apiResp = await resendOtp(postdata);
            dispatch(updateErrorCode(apiResp.code));
            if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
                dispatch(updateLoginData(apiResp?.data));

                dispatch(
                    updateSuccessMess(
                        "We've resent the OTP to your registered number/email"
                    )
                );
                setTimeRemaining(180);
            } else {
                dispatch(updateErrorCode(apiResp.code));
                dispatch(updateError(apiResp.message));
            }
            return apiResp.data;
        } catch (err) {
            let error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                dispatch(updateErrorCode(error.response?.data.code));
                dispatch(
                    updateError("An unexpected error occurred. Please try again later.")
                );
            } else {
                dispatch(
                    updateError("An unexpected error occurred. Please try again later.")
                );
            }
        }
    };



    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === "Enter" && newotp && newotp.length >= 6) {
                event.preventDefault();

                otpVerification();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [newotp]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeRemaining]);

    const isOtpComplete = useMemo(
        () => (newotp ? newotp.length < 6 : true),
        [newotp]
      );
    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    return (
        <div className="w-[80%] mx-auto">
            <h1
                className="text-GlobalColor font-bold text-xl text-left md:text-2xl "
                data-testid="verification"
            >
                Verification
            </h1>
            <div className="w-full  mt-6">
                <p className=" text-zinc-600 text-sm text-left md:text-center lg:text-left  lg:w-3/4 flex font-[500] md:text-sm  lg:font-normal ">
                    Please enter the verification code received on your registered mobile
                    number
                </p>
            </div>

            {/* <p
        className="font-medium  text-black text-sm my-2 text-center   lg:font-medium  lg:text-left w-full lg:w-3/5 lg:my-3 "
        data-testid="phone-four-digit"
      >
        ******{phoneNumber}
      </p> */}
            <p
                className="w-full font-normal text-center text-zinc-600 text-sm mt-2 mb-6 md:text-sm  lg:font-normal lg:text-left  lg:w-3/5"
                data-testid="timer-otp"
            >
                The code will expire in {minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
            </p>
            <div className="w-full ml-2">
                <InputOTP
                    maxLength={6}
                    pattern={NUMBER_PATTERN}
                    className=""
                    value={newotp}
                    onChange={(e: string) => setNewOtp(e)}
                    data-testid="otp-input"
                >
                    <InputOTPGroup className="">
                        <InputOTPSlot index={0} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={1} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={2} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={3} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={4} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={5} />
                    </InputOTPGroup>
                </InputOTP>
            </div>
            <div className="w-full">
                <div className="w-1/2 mt-2">
                <Buttons
          onClick={otpVerification}
          btnName={`${
            newotp?.length === 6 && timeRemaining !== 0 ? "CONFIRM" : "VERIFY"
          }`}
          data-testid="otpVerifys"
          id="otpVerify"
          disabled={timeRemaining === 0 || isOtpComplete}
          loading={loading}
        />
                </div>
                <h3 className="text-zinc-500 text-sm font-medium text-left mt-4 md:my-4 ml-2 ">
                    Didn’t receive any code?
                    <Button
                        className="text-red-500 text-sm font-semibold cursor-pointer ml-1 bg-white hover:bg-white border border-none"
                        // role="button"
                        disabled={!(timeRemaining === 0)}
                        onClick={resendAgainLogin}
                        variant={"link"}
                        type="button"
                    >
                        Resend
                    </Button>{" "}
                </h3>
            </div>
        </div>
    );
};

export default LOtp;
